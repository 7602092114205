import React, { useEffect, useRef } from "react"
import { Container, Row, Col } from "react-bootstrap"
import "./Faqs.scss"

const qstanws = [
  'To apply for a Jump Loan line of credit, you must be a legal resident of the state where Jump Loans is available. At this time, we only offer lines of credit to Kansas, Tennessee, and Alabama residents.<p></p> To begin your application, you’ll need:<ul className="pt-3 ml-5">   <li>Your social security number</li>    <li>Permanent address</li>    <li>Driver’s license</li>    <li>Proof of current employment </li>    <li>Banking information.</li>  </ul>',
  "At this time, we only allow one line of credit per household. ",
  "You’ll need a checking account that accepts ACH transfers to apply for a Jump Loans line of credit. Unfortunately, prepaid debit cards and savings accounts are not currently accepted. ",
  "Yes, we look at more than just your credit score when we make our decision about your line of credit qualification at Jump Credit. Though it does play an important part, we examine your credit history as a whole. Even if you’ve been turned down by other lenders before, we may be able to help you obtain a line of credit that fits your needs. ",
  "Created by Fair, Isaac and Company, FICO® Scores are credit scores used by 90% of the top lenders to help make billions of credit-related decisions every year. They are calculated solely on the information in consumer credit reports maintained by the three major credit bureaus - Experian, Equifax and TransUnion. By comparing your credit information to other thousands of other past credit reports, FICO® Scores estimate your level of future risk. ",
  "You’ll receive an email notifying you of your approval status once your application has been reviewed. ",
  "Once you receive an email from us letting you know you’ve been approved for a Jump Loans line of credit, it typically takes one business day, or 24 hours, for your fund to become available to you. ",
]

function questionIn(e) {
  console.log(e)
  if (document.querySelectorAll("li")[0].classList.contains("active")) {
    document.querySelectorAll("li")[0].classList.remove("active")
  }
  e.target.classList.add("active")
  document.getElementById("answerContainer").innerHTML =
    qstanws[e.target.dataset["position"]]
}
function questionOut(e) {
  e.target.classList.remove("active")
}

const FaqsComponent = () => {
  let svgRef = useRef(null)
  useEffect(() => {
    svgRef.current.querySelectorAll("li").forEach(question => {
      question.onmouseover = questionIn
      question.onmouseleave = questionOut
    })
  }, [])

  return (
    <Container fluid="xl" className="faqComponent">
      <Row>
        <Col className="ml-0 p-0 pr-5">
          <ul id="questionsList" ref={svgRef}>
            <li data-position="0" className="active">
              Q: What do I need to begin my application?{" "}
            </li>
            <li data-position="1">
              Q: Can I apply for multiple lines of credit?{" "}
            </li>
            <li data-position="2">
              Q: What kind of bank account is required to apply for a line of
              credit?{" "}
            </li>
            <li data-position="3">
              Q: Can I apply for a Jump Loans line of credit if my credit isn’t
              so great?{" "}
            </li>
            <li data-position="4">Q: What is a FICO® score? </li>
            <li data-position="5">Q: How do I know if I’ve been approved? </li>
            <li data-position="6">
              Q: How soon will I receive my funds once I’m approved?{" "}
            </li>
          </ul>
        </Col>
        <Col className="px-5">
          <div className="prefix active align-top">A: </div>
          <div className="align-top" id="answerContainer">
            To apply for a Jump Loan line of credit, you must be a legal
            resident of the state where Jump Loans is available. At this time,
            we only offer lines of credit to Kansas, Tennessee, and Alabama
            residents.<p></p> To begin your application, you’ll need:
            <ul className="pt-3 ml-5">
              <li>Your social security number</li>
              <li>Permanent address</li>
              <li>Driver’s license</li>
              <li>Proof of current employment </li>
              <li>Banking information.</li>
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default FaqsComponent
