import React from "react"
import { Container } from "react-bootstrap"
import FaqsComponent from "../components/Layout/faqsPage/Faqs"

const Portal = () => (
  <Container fluid className="type-1-bg p-0">
    <Container>
      <h1 className="pt-4 h1-header text-white"> FAQ's</h1>
      <FaqsComponent />
    </Container>
  </Container>
)

export default Portal
